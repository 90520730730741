import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";

const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <p>
                We specialize in developing seamless, dynamic websites and
                applications that serve as the cornerstone of your digital
                presence. Understanding that a great product must be seen, We
                integrate marketing strategies right from the development phase.
                <br></br> Leveraging the power of digital channels, We devise
                strategies that ensure your product doesn’t just launch but
                soars.<br></br> In today's digital era, social media is the
                heartbeat of online marketing.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="Image" />
                  <h5>Custom Website Design & Development</h5>
                </div>
                <div className="item">
                  <img src={meter1} alt="Image" />
                  <h5>
                    Integration Services (e.g., Paystack, Payfast Mailchimp)
                  </h5>
                </div>
                <div className="item">
                  <img src={meter2} alt="Image" />
                  <h5>Digital Flipbooks and E-Brochures</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="Image" />
                  <h5>SEO Services & SEO-Friendly Development</h5>
                </div>
                <div className="item">
                  <img src={meter1} alt="Image" />
                  <h5>Digital Marketing</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};

export default Skills;
