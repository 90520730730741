import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import Pricecard from "./Pricecard";
import projImg1 from "../assets/img/development.png";
import projImg2 from "../assets/img/marketing.png";
import projImg3 from "../assets/img/creation.png";
import projImg4 from "../assets/img/seo1.png";
import projImg5 from "../assets/img/cmarketing.png";
import projImg6 from "../assets/img/api.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

const Projects = () => {
  const projects = [
    {
      title: "Software Development:",
      description:
        "Tailored software solutions that bring your ideas to life. Specializing in building robust, scalable web applications that drive your business forward.",
      imgUrl: projImg1,
    },
    {
      title: "Digital Marketing",
      description:
        "Strategic digital marketing campaigns that enhance your online presence. From social media marketing to PPC and email campaigns, we help you reach your target audience effectively.",
      imgUrl: projImg2,
    },
    {
      title: "Content Creation",
      description:
        "Creative content creation that engages and retains your audience. We produce compelling videos, articles, and graphics that tell your brand's story and establish a strong online voice.",
      imgUrl: projImg3,
    },
    {
      title: "Search Engine Optimization (SEO)",
      description:
        "Improve your website's visibility on search engines through our comprehensive SEO strategies. Enhance your site's ranking and attract more organic traffic with our optimized content and technical SEO practices.",
      imgUrl: projImg4,
    },
    {
      title: "Content marketing",
      description:
        "Drive growth and engagement with our content marketing services. By leveraging SEO, social media, and targeted content, we help you build a loyal community and convert leads into customers.",
      imgUrl: projImg5,
    },
    {
      title: "Skilled in API integration ",
      description:
        "Seamlessly integrate third-party services and data into your applications. Our expertise in API integration enhances your software's capabilities and provides a richer user experience.",
      imgUrl: projImg6,
    },
  ];

  const projects1 = [
    {
      title: "Custom Website Design",
      description:
        "Discover our tailor-made website design services crafted to enhance your digital presence. We focus on creating unique, engaging, and user-friendly websites that resonate with your target audience and support your business goals.",
      pricing: "Starting from R3,500",
      imgUrl: projImg1,
    },
    {
      title: " Social Media Management",
      description:
        "We handle everything from content creation and scheduling to community engagement and analytics, helping you maintain an active and effective presence on key social platforms.",
      pricing: "Starting from R3,000",
      imgUrl: projImg2,
    },
    {
      title: "Advanced Google Analytics Services",
      description:
        "We help you go beyond basic traffic reports by integrating sophisticated tracking solutions that provide deep insights into user behavior and website performance. ",
      pricing: "Starting from R6,000 per setup.",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Transform Your Business </h2>
                  {/*}<p>we specialize in integrating artificial intelligence with digital strategies to revolutionize how businesses operate, market, and grow. Our AI consulting services are designed to help you leverage the latest in AI technology to gain a competitive edge.</p>*/}
                  <p style={{ textAlign: "center", width: "75%" }}>
                    We are not just a service provider; We are your digital
                    partner. We bring a holistic approach to projects, ensuring
                    that every aspect of your online presence, from development
                    to marketing, is interconnected and optimized for success.{" "}
                    <br />
                    <br />
                    Our mission is to help you achieve your digital goals,
                    whether it's launching a new product, increasing brand
                    awareness, or engaging with your audience on a deeper level.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">All services</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Digital Solutions</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Pricing & Intergration
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{ textAlign: "center", width: "100%" }}>
                            Elevate Your Brand with Stunning Digital Flipbooks
                            and E-Brochures:
                          </h3>
                          <p>
                            Do you always struggle when you want to showcase
                            your products quickly and easily, especially when
                            customers ask for your portfolio? Digital flipbooks
                            and e-brochures are the perfect solution!
                          </p>

                          <div className="containers">
                            <video
                              className="video"
                              controls
                              loop
                              autoPlay
                              muted
                              height="450px"
                            >
                              <source src="eflyers1.mp4" type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            <p>
                              Our innovative designs are perfect for showcasing
                              your products, services, and brand story in a
                              dynamic, engaging way. <br /> <br /> Whether it's
                              a tripod flyer, a double gatefold flyer, or an
                              interactive flipbook, we transform your content
                              into an immersive digital experience.
                            </p>
                          </div>
                          <p style={{ textAlign: "center", width: "100%" }}>
                            {" "}
                            <br />
                            <br />
                            Transform your marketing materials with our custom
                            digital flipbooks, designed to engage and impress
                            your audience. Starting at just R3,000, our service
                            includes hosting, ensuring your flipbooks are always
                            accessible and perfectly maintained.
                          </p>
                          <h5 style={{ textAlign: "center", width: "100%" }}>
                            {" "}
                            Get started today and transform your traditional
                            content into dynamic digital experiences that
                            captivate and convert. Contact us for a quote and
                            let’s bring your digital flyers to life!
                          </h5>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <h3 style={{ textAlign: "center", width: "100%" }}>
                          Pricing & Integration Services <br />
                        </h3>
                        <Row>
                          {projects1.map((project, index) => {
                            return <Pricecard key={index} {...project} />;
                          })}
                        </Row>
                        {/* Add more pricing details as needed */}

                        <section>
                          <h3 style={{ textAlign: "center", width: "100%" }}>
                            Integration Services
                          </h3>
                          <h5 style={{ textAlign: "center", width: "100%" }}>
                            Our goal is to help you optimize your business
                            operations with effective, efficient, and reliable
                            integration services that drive success and growth.
                          </h5>
                          <p style={{ textAlign: "center", width: "75%" }}>
                            In today's interconnected digital ecosystem,
                            seamless integration across various platforms is key
                            to operational efficiency and customer satisfaction.
                          </p>
                          <p style={{ textAlign: "center", width: "75%" }}>
                            At Lesedi Agency Innovations, we specialize in
                            integrating a wide range of systems including CRM,
                            e-commerce, payment gateways like Paystack and
                            Mailchimp, and more, ensuring that your business
                            tools work together harmoniously.
                          </p>
                          <p style={{ textAlign: "center", width: "75%" }}>
                            We understand that each business has unique needs,
                            which is why we offer customized integration
                            solutions tailored to your specific requirements.
                          </p>
                          <h5 style={{ textAlign: "center", width: "100%" }}>
                            Contact us to discuss your specific integration
                            needs and get a detailed quote.
                          </h5>
                          {/* Add more integration details as needed */}
                        </section>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};

export default Projects;
